import { render, staticRenderFns } from "./Homepage.vue?vue&type=template&id=5c1f6d52&scoped=true&"
import script from "./Homepage.vue?vue&type=script&lang=js&"
export * from "./Homepage.vue?vue&type=script&lang=js&"
import style1 from "./Homepage.vue?vue&type=style&index=1&id=5c1f6d52&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c1f6d52",
  null
  
)

export default component.exports